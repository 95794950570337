@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/source-sans-pro/SourceSansPro-Regular.otf")
    format("truetype");
  // font-family: "Inter";
  // src: url("../assets/fonts/Inter/static/Inter-Regular.ttf") format("truetype");
}

.page_name {
  margin-left: 15px;
  color: #0561a0;
  margin-bottom: 15px;
}

